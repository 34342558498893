//
// Popover
//


.popover {
    border: 0;
    min-width: 350px;
    max-width: 350px;
}

.popover-header {
	font-weight: $font-weight-bold;
}


// Alternative colors

@each $color, $value in $theme-colors {
    .popover-#{$color} {
        @include popover-variant($value);
    }
}
