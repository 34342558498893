.nunc-v1 {

@import './argon/argon.scss';

a {
  font-weight: 600;
  color: $dark;
  
  &:hover {
    color: $dark;
  }
}

a.unstyled {
  font-weight: inherit;
  color: inherit;

  &:hover {
    color: inherit;
  } 
}

body {
  background-color: #ffffff;
}

.component-condition {
  border-top: none !important;
}

.component-condition:first-child {
  border-top: 1px solid #e9ecef !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.component-condition:last-child {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

// let me know if this rule should be elsewhere -MM
.input {
  border-radius: 4px;
  border: 1px solid $input-border-color;
  padding: 5px;
  width: 100%;
  height: 45px;
}

.event-border {
  border-width: 2px !important;
}

.bg-header-gradient {
  background: linear-gradient(90deg, #000000 0%, #414141 100%);
}

.header-alert {
  border-radius: 15px;
}

.badge-incident {
  background: linear-gradient(90deg, #F85151 0%, #E38D1A 100%);
}

.badge-maintenance {
  background: linear-gradient(90deg, #9055F3 0%, #195FA2 100%);
}

.badge {
  text-transform: none !important;
  font-size: 75% !important;
}

.oval-xl {
  height: 103px;
  width: 103px;
  box-shadow: 2px 4px 12px 1px rgba(0,0,0,0.14);
  border-radius: 100%;
}

.oval-md {
  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.oval-sm {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.bg-warning-gradient {
  background: linear-gradient(180deg, #FF9741 0%, #FFDB1D 100%);
}

.bg-success-gradient {
  background: linear-gradient(180deg, #5DAD30 0%, #6DD400 100%);
}

.separator {
  height: 1px;
  width: 103px;
  border: 1px solid #FFA141;
}

.no-shadow {
  box-shadow: none;
}

.sticky {
  position: sticky;
  top: 2rem;
}

strong {
  color: $gray-900;
}

.incident-summary:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.incident-event:last-child {
  border-bottom: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.hr-sect {
	display: flex;
	flex-basis: 100%;
	align-items: center;
}

.hr-sect::before,
.hr-sect::after {
	content: "";
	flex-grow: 1;
	background: #ADB5BD;
	height: 1px;
	font-size: 0px;
	line-height: 0px;
  right: 5px;
}

.bg-shade {
  background-color: $gray-100;
}
}

