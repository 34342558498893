@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  h3 {
    font-weight: 500;
  }
}
